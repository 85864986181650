
.side-nav {
    position: fixed;
    top: $header-height;
    width: $side-nav-width;
    background-color: $white;
    border-right: 1px solid $border-color;
    height: 100%;
    z-index: $zindex-navbar;
    // overflow-y: auto;
    @include transition(all 0.2s ease);

    @include screen-tablet {
        left: -$side-nav-width;
    }

    @include screen-laptop-only {
        width: $side-nav-width-laptop;
    }
    .ps-content{
      overflow: scroll;
      height: 100%;
    }
    // .ps-content{
    //   -ms-overflow-style: none;  /* IE and Edge */
    //   scrollbar-width: none;  /* Firefox */
    // }
    ::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;
    }
    .side-nav-menu {
        margin-bottom: $header-height;

        &:not(.ant-menu-inline-collapsed) {
            .dropdown-menu {
                display: none;
            }

            .ant-menu-submenu-open {

                > .dropdown-menu {
                    display: block !important;
                }
            }
        }

        &.ant-menu-inline-collapsed {

            .ant-menu-submenu {
                position: relative;

                .ant-menu {
                    visibility: hidden;
                    opacity: 0;
                    position: absolute;
                    top: 0px;
                    left: 100%;
                    min-width: 250px;
                    margin-left: 4px;
                    border-radius: $border-radius;
                    transition: all 0.2s ease-in-out;
                }

                &:hover {
                    >.ant-menu {
                        visibility: visible;
                        opacity: 1;
                    }
                }

                &.ant-menu-submenu-inline {

                    > .ant-menu-submenu-title {

                        .ant-menu-submenu-arrow {

                            &:after {
                                @include transform(rotate(-45deg) translateX(-2px));
                            }

                            &:before {
                                @include transform(rotate(-130deg) translateX(2px));
                            }
                        }
                    }
                }
            }

            .ant-menu-sub {
                box-shadow: 0 2px 8px rgba(0,0,0,.15);
            }

            .p-l-50 {
                padding-left: 20px !important;
            }

            .p-l-30 {
                padding-left: 0px !important;
            }
        }
    }
}

.common-layout {
    perfect-scrollbar {
        overflow: visible;

        .ps {
            overflow: visible !important;
        }

        .ps__rail-y,
        .ps__rail-x {
            display: none;
        }
    }
}

.is-folded {
    .side-nav {
        width: $side-nav-folded;

        .ant-menu-inline-collapsed {
            width: $side-nav-folded - 1px;
        }
        .ps-content{
          overflow: unset;
        }
    }

    perfect-scrollbar {

        overflow: visible;

        .ps {
            overflow: visible !important;
        }

        .ps__rail-y,
        .ps__rail-x {
            display: none;
        }
    }
}

.is-expand {
    @include screen-tablet {
        .side-nav {
            left: 0px;
        }
    }
}

.is-side-nav-dark {
    .side-nav {
        background-color: $menu-dark-bg;
    }
}

@each $theme in $theme-color-loop {
    .#{nth($theme, 1)} {

        &:not(.is-side-nav-dark) {

            .side-nav-menu {
                .ant-menu:not(.ant-menu-horizontal)  {

                    .ant-menu-item-selected {
                        background: rgba(nth($theme, 2), 0.1);

                        a {
                            color: #{nth($theme, 2)};
                        }
                    }
                }

                .ant-menu-inline,
                .ant-menu-vertical,
                .ant-menu-vertical-left,
                .ant-menu-vertical-right {
                    .ant-menu-item {
                        &:after {
                            border-right: 3px solid #{nth($theme, 2)};
                        }
                    }
                }

                .ant-menu-item,
                .ant-menu-submenu-title {

                    &:active {
                        background: rgba(nth($theme, 2), 0.1);
                    }

                    > a {

                        &:hover {
                            color: #{nth($theme, 2)};
                        }
                    }
                }

                .ant-menu-submenu-title {
                    &:hover {
                        color: #{nth($theme, 2)};

                        .ant-menu-submenu-arrow{
                            &:after,
                            &:before {
                                background: linear-gradient(to right, nth($theme, 2), nth($theme, 2))
                            }
                        }
                    }
                }
            }
        }
    }
}

.side-nav .side-nav-menu .ant-menu-submenu {
    position: relative;
}

.side-nav .side-nav-menu .ant-menu-submenu .ant-menu-cap3 {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    // top: 0px;
    left: -5000px;
    min-width: 250px;
    margin-left: 0px;
    border-radius: 0.25rem;
    // transition: all 0.2s ease-in-out;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.side-nav .side-nav-menu .ant-menu-cap2:hover > .ant-menu-cap3 {
    visibility: visible;
    opacity: 1;
    display: block;
    transition: opacity .5s;
    background-color: white;
    left: 100%;
}
.side-nav .side-nav-menu li.ant-menu-item:hover {
    background: #3f87f5;
    background: rgba(63, 135, 245, 0.1);
}
.ant-menu-item{
    margin-top: 4px;
    margin-bottom: 4px;
}

.ant-menu-cap2 > .ant-menu-submenu-title i.ant-menu-submenu-arrow{
    &:after {
        @include transform(rotate(-45deg) translateX(-2px));
    }

    &:before {
        transform: rotate(-130deg) translateX(2px) !important;
        -webkit-transform: rotate(-130deg) translateX(2px)!important;
        -moz-transform: rotate(-130deg) translateX(2px)!important;
        -o-transform: rotate(-130deg) translateX(2px)!important;
        -ms-transform: rotate(-130deg) translateX(2px)!important;
        -webkit-transform: rotate(-130deg) translateX(2px)!important;
        //@include transform(rotate(-130deg) translateX(2px));
    }
}
