.checkbox-zoom {
    zoom: 1.4;
}

.border-span {
    border: 1px solid #EDF2F9;
    color: #535353;
    border-radius: 50%;
    padding: 12px;
    font-size: 16px;
}

.chuThich {
    font-size: 0.8rem;
    font-style: italic;
    color: #80808096;
}

.ant-btn-success {
    background-color: green;
    border-color: green;
}

.ant-btn-success:hover,
.ant-btn-success:focus {
    color: #fff;
    background-color: green;
    border-color: green;
}

.ant-btn-warring {
    background-color: orange;
    border-color: orange;
}

.ant-btn-warring:hover,
.ant-btn-warring:focus {
    color: #fff;
    background-color: orange;
    border-color: orange;
}


input:read-only {
    background-color: #f7f7f7;
}

.disableIcon {
    color: rgb(87 84 84 / 49%);
}

input::placeholder {
    opacity: 0.6;
    color: #000000 !important;
}

.ant-select-selection-placeholder {
    opacity: 0.6;
    color: #000000 !important;
  }
  .logo-fold{

    max-height: 50px;
    margin: auto;
  }
  .img-logo{
    width: 130px;
    border: none;
  }

  .ant-table-content .ant-tag{

margin: 0px !important;
  }
  perfect-scrollbar{
    overflow: hidden;
  }
 .ant-radio-inner:after {
    background-color: #3f87f5;
  }
.ant-radio-inner::after{
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    border-top: 0;
    border-left: 0;
    border-radius: 16px;
    margin:-3px;
    content: ' ';
  }

  .bg-login{
   // background-image: url('/assets/images/bg.png'); 
    background: url('/assets/images/bg.png') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  /* CSS hiệu ứng bóng lướt NGHIÊNG cho logo by GIUSEART.com */
 
/* Sửa #logo thành phần tử CSS của bạn */
#logo:after,#logo:before{transform: skewX(-25deg) translateY(-50%);content:"";position:absolute;top:50%;width:40px;height:100%;background-color:white;z-index:0}
#logo:before{left:0%;animation:light-left 5s infinite alternate linear}
#logo:after{right:-5%;animation:light-right 5s infinite alternate linear}
 
@keyframes light-left{0%{left:-5%;opacity:0}50%{left:50%;opacity:1}to{left:105%;opacity:0}}@keyframes light-right{0%{right:-5%;opacity:0}50%{right:50%;opacity:1}to{right:105%;opacity:0}}



.content-container {
  position: relative;

  overflow: auto;
  height: 300px;
  min-height: 0;
  border-radius: 4px;
  background-color: #fff;
}




/* ticket-ve */
.ticket-avatar{
  background: #f1f2f3;
  color: #fff;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    border-radius: 0;
}
.ticket-avatar-circle{
  border-radius: 50%;
}
.ticket-avatar-square{
  border-radius: 0;
}
.ticket-avatar > img{
  width: 100%;
}

.ng-select.ng-select-single .ng-select-container {
  height: 41px;
}